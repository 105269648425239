.app-title {
  float: left;
  width: 165px;
  height: 31px;
  font-size: 20px;
  font-weight: bold;
  color: #a62a2a;
}

.ant-table-summary td {
  background: #fafafa;
}

.block {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 3px;
}

@media print {
  li:not(.ant-menu-item-selected) {
    display: none !important;
  }

  .no-print {
    display: none;
  }

  main {
    padding: 0 !important;
  }

  section {
    background: transparent !important;
  }

  table {
    border: 1px solid #aaa;
  }

  footer {
    display: none;
  }

  .reserved-column-header {
    writing-mode: vertical-rl;
  }
}

.qr-column {
  text-align: center;
  padding-top: 50px;
}

.qr-column div {
  display: inline-block;
}

.qr-column h3 {
  font-weight: normal;
}

section.test {
  background: #dcbeff;
}